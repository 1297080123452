<template>
  <div>
    <div class="card mb-3">
      <div class="card-header">
        <div class="row">
          <div class="col-6">
            <strong>Quotes <span v-if="quotesCount !== null">({{ quotesCount }})</span></strong>
          </div>
          <div class="col-6 text-right">
            <a href="https://vatstack.com/docs/quotes" target="_blank">API Reference <i class="fal fa-external-link ml-1"></i></a>
          </div>
        </div>
      </div>
      <div class="card-body text-center">
        <small>
          <i class="fas fa-exclamation-triangle text-warning mr-2"></i> Quotes are stored for 3 days and then deleted.
        </small>
      </div>
      <BaseSpinner :loading="loading">
        <table class="table mb-0">
          <thead class="thead-light">
            <tr>
              <th>Country</th>
              <th>Total Amount</th>
              <th>VAT (%)</th>
              <th>Created</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="quote in quotes"
              :key="quote.id"
            >
              <td>
                <router-link :to="{ name: 'quotes-id', params: { id: quote.id }}" class="text-dark">
                  {{ quote.country_name || $store.state.countries[quote.country_code] }}
                </router-link>
              </td>
              <td>
                {{ convertPrice(quote.amount_total) }}
              </td>
              <td>
                {{ convertPrice(quote.vat.amount) }}
                ({{ quote.vat.rate }}%)
              </td>
              <td>
                {{ convertDateTime(quote.created) }}
              </td>
            </tr>
            <tr v-if="!quotesCount">
              <td class="text-muted text-center" colspan="4">
                <i class="fad fa-empty-set mr-1"></i> No quotes were found.
              </td>
            </tr>
          </tbody>
        </table>
      </BaseSpinner>
      <div class="card-footer">
        <BasePagination
          v-model="filters.page"
          :hasMore="hasMore"
          :pageCount="Math.ceil(quotesCount / filters.limit)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BasePagination from '@/components/Base/BasePagination'
import BaseSpinner from '@/components/Base/BaseSpinner'

export default {
  components: {
    BasePagination,
    BaseSpinner,
  },

  data() {
    return {
      loading: true,
      hasMore: false,
      filters: {
        limit: 20,
        page: Number(this.$route.query.page) || 1,
      },
      quotes: [],
      quotesCount: null,
    }
  },

  mounted() {
    this.listQuotes()
  },

  methods: {
    async listQuotes() {
      try {
        this.loading = true

        var { data } = await this.$http.get(`/quotes`, {
          headers: { 'x-api-key': this.$store.getters.secretKey },
          params: { page: this.filters.page },
        })

        this.hasMore = data.has_more
        this.quotes = data.quotes
        this.quotesCount = data.quotes_count
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      } finally {
        this.loading = false
      }
    },
    convertPrice(price) {
      return (price / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    },
    convertDateTime(date) {
      return date && `${ date.toString().slice(0, 10) } ${ date.toString().slice(11, 16) }`
    },
  },

  watch: {
    '$store.state.livemode'() {
      if (this.filters.page !== 1) {
        this.$router.replace({ name: 'quotes' })
      } else {
        this.listQuotes()
      }
    },
    'filters.page'(value) {
      this.$router.push({ name: 'quotes', query: { ...this.$route.query, page: value }})
    },
  },
}
</script>
