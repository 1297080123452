<template>
  <div>
    <BaseSpinner v-if="loading" :loading="loading" />
    <div v-else class="card mb-3">
      <div class="card-header">
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'quotes' }"><i class="fal fa-lg fa-chevron-left mr-2"></i> Quotes</router-link>
          </li>
          <li class="breadcrumb-item active">
            {{ quote && quote.id }}
          </li>
        </ul>
      </div>
      <table class="table mb-0">
        <thead>
          <tr class="thead-light">
            <th class="w-25">Key</th>
            <th class="w-75">Value</th>
          </tr>
          <tr>
            <td class="text-muted align-top">Charge</td>
            <td>
              <div class="row">
                <div class="col-12 col-lg-4 mb-3 mb-lg-0">
                  <label class="label">Total Amount</label>
                  <div>
                    {{ convertPrice(quote.amount_total) }}
                  </div>
                </div>
                <div class="col-12 col-lg-4 mb-3 mb-lg-0">
                  <label class="label">VAT Rate</label>
                  <div>
                    {{ quote.vat.rate}}%
                    <span v-if="quote.vat.rate_type === 'standard'" class="badge badge-light ml-2">{{ quote.vat.rate_type }}</span>
                    <span v-else-if="quote.vat.rate_type === 'reduced'" class="badge badge-light ml-2">{{ quote.vat.rate_type }}</span>
                    <span v-else class="badge badge-light ml-2">{{ quote.vat.rate_type }}</span>
                  </div>
                </div>
                <div class="col-12 col-lg-4 mb-3 mb-lg-0">
                  <label class="label">VAT Amount</label>
                  <div>
                    {{ convertPrice(quote.vat.amount) }} ({{ quote.vat.inclusive ? 'inclusive' : 'exclusive' }})
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="text-muted">Category</td>
            <td>
              <div v-if="quote.category">{{ quote.category }}</div>
              <div v-else class="text-muted"><small>Not available</small></div>
            </td>
          </tr>
          <tr>
            <td class="text-muted">IP Address</td>
            <td>
              <div v-if="quote.ip_address">{{ quote.ip_address }}</div>
              <div v-else class="text-muted"><small>Not available</small></div>
            </td>
          </tr>
          <tr>
            <td class="text-muted">Country</td>
            <td>
              {{ quote.country_name || $store.state.countries[quote.country_code] }} ({{ quote.country_code }})
              <span v-if="quote.member_state" class="badge badge-info ml-2">Member State</span>
            </td>
          </tr>
          <tr>
            <td class="text-muted"><i class="fal fa-paperclip mr-1"></i> Validation</td>
            <td>
              <div v-if="quote.validation">
                <router-link :to="{ name: 'validations-id', params: { id: quote.validation.id }}" class="color-inherit">
                  <a-tooltip v-if="quote.validation.valid === true" title="Valid" placement="left">
                    <i class="fad fa-check-circle text-success mr-1"></i> {{ quote.validation.query }}
                  </a-tooltip>
                  <a-tooltip v-else-if="quote.validation.valid === false" title="Invalid" placement="left">
                    <i class="fad fa-times-circle text-danger mr-1"></i> {{ quote.validation.query }}
                  </a-tooltip>
                  <a-tooltip v-else :title="$store.state.errorMessages[quote.validation.code]" placement="left">
                    <i class="fad fa-clock text-muted mr-1"></i> {{ quote.validation.query }}
                  </a-tooltip>
                </router-link>
              </div>
              <div v-else class="text-muted"><small>Not available</small></div>
            </td>
          </tr>
          <tr>
            <td class="text-muted">Created</td>
            <td>{{ convertDateTime(quote.created) }}</td>
          </tr>
        </thead>
      </table>
      <div class="card-footer text-muted">
        <small>Last updated {{ convertDateTime(quote.updated) }}</small>
      </div>
    </div>
    <div class="card mb-3">
      <div class="card-header">
        <strong>Raw JSON</strong>
      </div>
      <pre class="rounded-bottom p-4"><code>{{ quote }}</code></pre>
    </div>
  </div>
</template>

<script>
import BaseSpinner from '@/components/Base/BaseSpinner'

export default {
  components: {
    BaseSpinner,
  },

  data() {
    return {
      loading: true,
      quote: null,
    }
  },

  async mounted() {
    try {
      var { data } = await this.$http.get(`/quotes/${ this.$route.params.id }`, {
        headers: { 'x-api-key': this.$store.getters.secretKey },
      })

      this.loading = false
      this.quote = data
    } catch (err) {
      if (err.response) {
        if (err.response.status === 404) {
          this.$router.push({ name: '404' })
        }

        this.$message.error(err.response.data.message)
      } else {
        this.$message.error(err.message)
      }

      return false
    }
  },

  methods: {
    convertPrice(price) {
      return (price / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    },
    convertDateTime(date) {
      return date && `${ date.toString().slice(0, 10) } ${ date.toString().slice(11, 16) }`
    },
  },

  watch: {
    '$store.state.livemode'() {
      this.$router.replace({ name: 'quotes' })
    },
  },
}
</script>
